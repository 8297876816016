import React, { Component } from "react";

import Header from "./components/Header";
import Navigation from "./components/Navigation";

export default class Hosting extends Component {
  render() {
    return (
      <div id="page-top">
        <Navigation navTo="/" />
        <Header />
      </div>
    );
  }
}
