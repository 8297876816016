import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavLink,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { Link, Events, animateScroll as scroll, scroller } from "react-scroll";

class Navigation extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      navTo: props.navTo ? props.navTo : "#page-top"
    };
    this.scrollToTop = this.scrollToTop.bind(this);
  }
  componentDidMount() {
    Events.scrollEvent.register("begin", function() {
      // console.log("begin", arguments);
    });

    Events.scrollEvent.register("end", function() {
      // console.log("end", arguments);
    });
  }
  scrollToTop() {
    scroll.scrollToTop();
  }
  scrollTo() {
    scroller.scrollTo("scroll-to-element", {
      duration: 400,
      delay: 0,
      smooth: "easeInOutQuart"
    });
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  componentWillUnmount() {
    Events.scrollEvent.remove("begin");
    Events.scrollEvent.remove("end");
  }
  render() {
    return (
      <div>
        <Navbar className="bg-dark" dark fixed="top" expand="md">
          <NavLink
            className="nav-link"
            href={this.state.navTo}
            to={this.state.navTo}
            spy={true.toString()}
            smooth={true.toString()}
            onClick={() => this.scrollTo()}
          >
            jetsquared
          </NavLink>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <Link
                  href="#free"
                  className="nav-link"
                  offset={-56}
                  to="free"
                  spy={true}
                  smooth={true}
                  onClick={() => this.scrollTo()}
                >
                  FREE
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  href="#services"
                  className="nav-link"
                  to="services"
                  offset={-56}
                  spy={true}
                  smooth={true}
                  onClick={() => this.scrollTo()}
                >
                  Services
                </Link>
              </NavItem>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  Portfolio
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>Option 1</DropdownItem>
                  <DropdownItem>Option 2</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem>Reset</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

Navigation.propTypes = {
  navTo: PropTypes.string
};

export default Navigation;
