import React, { Component } from "react";
import jetsquared_White from "./img/jetsquared_White.png";

export default class Header extends Component {
  render() {
    return (
      <header className="masthead thankyou_img text-white d-flex">
        <div className="container my-auto">
          <div className="row  muted_background">
            <div className="col-md-7 mx-auto">
              <h1>
                Thanks for reaching out!
                <hr className="w50 light left" />
              </h1>
              <h2>
                We’re thrilled to hear from you.
                <hr className="w50 light left" />
              </h2>
              <h4>
                We have received your message and will respond within 24 hrs.
              </h4>
              {/* <p>Building solutions that work for you.</p> */}
            </div>
            <div className="img-div col-md-5 mx-auto pt-5 mr-3">
              <img
                className="hero-image float-right"
                src={jetsquared_White}
                alt="jetsquared_logo"
              />
            </div>
            <hr />
          </div>
        </div>
      </header>
    );
  }
}
